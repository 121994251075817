import React from "react"
import styled from "@emotion/styled"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import Background from "../components/background"
import { color } from "../utils"

const ContactWrapper = styled.div`
  position: relative;
  background-color: ${color.backgroundTransparencyBlack};
  min-height: 100vh;
  z-index: 2;
  margin: 0;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  @media screen and (min-width: 768px) {
    margin: 2% 8%;
    min-height: 70vh;
  }

  @media screen and (min-width: 1000px) {
    margin: 3% 10%;
  }

  opacity: 0;
  animation: fade 1.5s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }
`
const Title = styled.h1`
  &.title {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
    color: #ff0a78;
    font-weight: 900;
  }

  @media screen and (min-width: 768px) {
    &.title {
      font-size: 2.5rem;
    }
  }
`
const TextMain = styled.p`
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 1.7rem;
  color: ${color.textBackgroundBlack};

  span {
    font-size: 1.2rem;
    color: #ffe100;
  }

  a,
  a:visited {
    color: inherit;
    text-decoration: underline;
  }
  a:hover {
    color: #fb3c76;
  }
`

const Thanks = () => {
  return (
    <Layout>
      <SEO title="Thanks!" />
      <Background opacity="low" />

      <ContactWrapper>
        <Title className="title">THANKS FOR YOUR CONTACT!</Title>
        <TextMain>
          I'll get back to you as soon as possible.
          <span role="img" aria-label="smiley face">
            {" "}
            😀{" "}
          </span>{" "}
        </TextMain>
      </ContactWrapper>
    </Layout>
  )
}

export default Thanks
